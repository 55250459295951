<template>
  <div v-if="$route.params.reportId !== undefined">
    <KTPortlet v-if="portalReport === null || definedReport === null" title="SEO Report" data-app="seo-report">
      <template slot="body">
        <Loader :inContent="true" :noText="true">Loading...</Loader>
      </template>
    </KTPortlet>
    <div v-else class="row flex center">
      <KTPortlet :title="`${ definedReport.report.title } for ${ portalReport.info.title }`" data-app="seo-report"
        style="width: 100%; max-width: 880px; text-align: center;">
        <template v-slot:title>
          <b-button @click="$router.push(`/Web/${ $route.params.serviceId }/SEOReports`)" pill
            size="sm" variant="outline-primary text-primary">&#60;
          </b-button>
          <h3 class="kt-portlet__head-title" style="margin-left: 10px;">
            {{ `${ definedReport.report.title } for ${ portalReport.info.title }` }}
          </h3>
        </template>
        <template v-slot:toolbar>
          <div v-if="definedReport.instance.length < 2"></div>
          <div v-else>
            <b-select :options="definedReport.instance" item-text="name" item-value="id" :required="true"
              v-model="definedReport._instance" label="Date"></b-select>
          </div>
        </template>
        <template slot="body">
          <iframe border="none" style="border: none; height: 4095px; text-align: center; overflow: visible;"
            :src="'https://myinsights.io' + definedReport._instance"></iframe>
        </template>
      </KTPortlet>
    </div>
  </div>
  <div v-else>
    <KTPortlet title="SEO Reports" data-app="seo-report">
      <template slot="body">
        <div v-if="portalReport !== null && portalReport.reports.length === 0">No reports to display</div>
        <b-table-simple responsive v-else>
          <b-thead>
            <b-tr>
              <b-th>Name</b-th>
              <b-th>Status</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-if="portalReport === null">
            <b-tr v-for="i in 10" v-bind:key="i">
              <b-th>
                <Loader :newStyle="1" />
              </b-th>
              <b-td>
                <Loader :newStyle="1" />
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tbody v-else>
            <b-tr v-for="ireport in portalReport.reports" v-bind:key="ireport.id">
              <b-th>{{ ireport.report.title }}
              </b-th>
              <b-td>{{ ireport.report.generationStatus }}
              </b-td>
              <b-td style="width: 110px; text-align:right;">
                <b-button @click="$router.push(`/Web/${ $route.params.serviceId }/SEOReports/${ ireport.report.id }`)" pill
                  size="sm" variant="primary text-light">VIEW
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
import TLib from "./lib";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
//import ApiService from "@/common/api.service";

export default {
  components: {
    KTPortlet,
    Loader,
    apexcharts: VueApexCharts
  },
  watch: {},
  data() {
    return {
      portalReport: null,
      /*showConversions: false,
      stats: null,
      usage: null,
      start: null,
      end: null,
      chartOptions: {
        colors: ["#0091ea", "#6200ea", "#00c853", "#ffd600"],
        chart: {
          type: "area",
          height: 350
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },

        xaxis: {
          labels: {
            formatter: function(value, timestamp) {
              return moment(new Date(timestamp)).format("DD/MM/YYYY");
            }
          },
          type: "datetime",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 4,
          floating: false,

          labels: {
            / *formatter: function(value) {
              return (value * (value < 0 ? -1 : 1)).toFixed(2) + " GB";
            },* /
            offsetY: -7,
            offsetX: 0
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 0.5
        },
        tooltip: {
          x: {
            format: "datetime"
          },
          fixed: {
            enabled: false,
            position: "topRight"
          }
        },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30
            }
          },
          padding: {
            left: 20
          }
        }
      },
      suggestion: null*/
    };
  },
  methods: {
    /*getReportInstanceById(report) {
      for (let instance of report.instance) {
        if (instance.id === report._instance) return instance;
      }
      return null;
    },*/
    updateService(serviceId) {
      let self = this;
      self.$data.portalReport = null;
      /*self.$data.suggestion = null;
      TLib.getAdBudSuggestions(self, serviceId).then(sugg => {
        if (sugg.description === undefined) return;
        if (sugg.completed === true) return;
        self.$data.suggestion = sugg;
      }).catch(self.$log.error);*/
      TLib.semrushGetReport(self, serviceId)
        .then(z => {
          for (let reportIndex = 0; reportIndex < z.reports.length; reportIndex++) {
            if (z.reports[reportIndex].instance.length == 0) continue;
            z.reports[reportIndex]._instance = z.reports[reportIndex].instance[0].interactiveHtmlLink;
            //z.reports[reportIndex].instance.push(JSON.parse(JSON.stringify(z.reports[reportIndex].instance[0])));
            //z.reports[reportIndex].instance[1].generatedAt = '2022-10-21T11:42:47.000Z';
            //z.reports[reportIndex].instance[1].interactiveHtmlLink = '2022-10-21T11:42:47.000Z';

            for (let instanceIndex = 0; instanceIndex < z.reports[reportIndex].instance.length; instanceIndex++) {
              z.reports[reportIndex].instance[instanceIndex].text = moment(z.reports[reportIndex].instance[instanceIndex].generatedAt).format('DD/MM/YYYY');
              z.reports[reportIndex].instance[instanceIndex].value = z.reports[reportIndex].instance[instanceIndex].interactiveHtmlLink;
            }
          }

          if (self.$data.portalReport === -1) return;
          self.$data.portalReport = z;
          if (self.$route.params.reportId !== undefined) return;
          if (z.reports.length === 1) {
            self.$router.push(`/Web/${ self.$route.params.serviceId }/SEOReports/${ z.reports[0].report.id }`);
          }
        })
        .catch(self.$log.error);
      // return;
      // self.updateUsage(serviceId);
      // self.$data.service = null;
      // TLib.getSIM(this, serviceId)
      //   .then(x => {
      //     self.$nextTick(() => {
      //       self.$data.service = x;
      //     });
      //   })
      //   .catch(x => {
      //     self.$log.error(x);
      //     self.$log.error("Error loading content");
      //   });
    }
    /*updateUsage(serviceId) {
      let self = this;
      self.$data.usage = null;
      TLib.getUsage(this, serviceId)
        .then(x => {
          self.$nextTick(() => {
            self.$data.usage = x;
          });
        })
        .catch(x => {
          self.$log.error(x);
          self.$log.error("Error loading content");
        });
    }*/
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    definedReport() {
      if (this.$route.params.reportId === undefined) return null;
      for (let report of this.portalReport.reports) {
        if (this.$route.params.reportId == report.report.id) return report;
      }
      return null;
    },

    /*chartData() {
      let data = [
        {
          name: "Clicks",
          data: []
        },
        {
          name: "Impressions",
          data: []
        }
      ];

      if (this.showConversions) {
        data.push({
          name: "Conversions",
          data: []
        });
      }

      if (this.$tools.isNullOrUndefined(this.stats)) return null;
      if (this.$tools.isNullOrUndefined(this.stats.account_daily)) return null;

      for (let itemI of Object.keys(this.stats.account_daily)) {
        let item = this.stats.account_daily[itemI];
        data[0].data.push({
          x: item.date,
          y: item.clicks
        });
        data[1].data.push({
          x: item.date,
          y: item.impressions
        });
        if (this.showConversions) {
          data[2].data.push({
            x: item.date,
            y: item.conversions
          });
        }
      }

      return data;
    }*/
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
    this.$data.portalReport = -1;
    //this.$eventBus.off(`cache-crm:services`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Web" },
      { title: "SEO Report" }
    ]);

    /*if (
      !this.$tools.isNullOrUndefined(this.config.charts) &&
      !this.$tools.isNullOrUndefined(this.config.charts.colours)
    )
      this.$data.chartOptions.colors = this.config.charts.colours;*/

    this.updateService(this.$route.params.serviceId);

    let self = this;

    self.$eventBus.on(`switch-service-selected`, x => self.updateService(x));
    //self.$eventBus.on(`cache-crm:services`, () => self.resync(false));
    //self.resync();
  }
};
</script>
